import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "../components/Theme"
import Layout from "../components/Layout/layout"
import Login from "../components/Login/Login"

const LoginPage = () => <Login />

export default () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <LoginPage />
    </Layout>
  </ThemeProvider>
)

import React, { Component, useState } from "react"
import { Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const ButtonWithIcon = ({ text, icon, clicked, tag }) => {
  const classes = useStyles()

  const onClick = e => {
    clicked(e)
  }
  return (
    <Button className={classes.btn} variant="outlined" onClick={onClick}>
      <img className={classes.icon} src={icon} alt={text} />
      <Typography className={classes.btnText}>{text}</Typography>
    </Button>
  )
}

const useStyles = makeStyles(theme => ({
  btn: {
    marginTop: "1em",
    marginBottom: "0.5em",
    width: "100%",
    height: "50px",
    border: "2px solid",
    borderColor: "#D1D1D1",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "0 5px 5px #f1f1f1",
      borderColor: "#000000",
    },
  },
  icon: {
    height: "1.3em",
  },
  btnText: {
    textTransform: "none",
    margin: "auto",
    color: "#484848",
  },
}))

export default ButtonWithIcon

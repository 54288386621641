import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Card, CardContent, Typography, Button } from "@material-ui/core"
import { navigate } from "gatsby"
import mail from "../../images/mail.svg"
import fb from "../../images/fb.svg"
import google from "../../images/google.svg"
import ColoredLine from "../ColoredLine"
import ButtonWithIcon from "../ButtonWithIcon"
import { withFirebase } from "../Firebase"

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential"

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }
  handleGoogle = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        })
      })
      .then(() => {
        this.setState({ error: null })
        navigate("/")
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  handleFacebook = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        })
      })
      .then(() => {
        this.setState({ error: null })
        navigate("/")
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Card variant="outlined" className={classes.container}>
          <CardContent className={classes.innerWrapp}>
            <Typography className={classes.login}>Log in</Typography>
            {/* <ColoredLine color="#484848" text="or" /> */}

            <ButtonWithIcon
              text="Sign in with email"
              icon={mail}
              tag="1"
              clicked={e => this.handleGoogle(e)}
            />
            <ButtonWithIcon
              text="Sign in with google"
              icon={google}
              tag="2"
              clicked={e => this.handleGoogle(e)}
            />
            <ButtonWithIcon
              text="Sign in with facebook"
              icon={fb}
              tag="3"
              clicked={e => this.handleFacebook(e)}
            />
            {error && <p>{error.message}</p>}
          </CardContent>
        </Card>
        <Typography className={classes.footerText}>
          By continuing, you are indicating that you accept our <br />{" "}
          {
            <a href="https://www.fisherly.com/terms-and-conditions">
              Terms of Service
            </a>
          }{" "}
          and{" "}
          {<a href="https://www.fisherly.com/privacy-policy">Privacy Policy</a>}
          .
        </Typography>
      </div>
    )
  }
}

const SignIn = withFirebase(SignInGoogleBase)

const styles = theme => ({
  root: {
    width: "25em",
    height: "25em",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  container: {
    width: "25em",
    textAlign: "center",
    marginTop: "3em",
  },
  innerWrapp: {
    padding: "25px",
  },
  login: {
    fontSize: "32px",
    fontWeight: "bold",
    marginBottom: "20px",
    textTransform: "capitalize",
  },
  footerText: {
    color: "#757575",
    fontSize: "12px",
    lineHeight: "16px",
    marginTop: 0,
    padding: "1em",
    textAlign: "center",
  },
})

export default withStyles(styles)(SignIn)
